import React, { useState, useRef } from "react"
import Div100vh from "react-div-100vh"
import styled from "styled-components"
import { randQustions, randQustions2 } from "../lib/quiz"
import ShowMessage from "./PageElements/ShowMessage"
import { Box, Button, Input } from "./PageElements/UIElements"
import ShowConfetti from "./ShowConfetti"
import useAppStore from "../stores/application"
import useStore from "zustand"
import Form from "react-bootstrap/Form"
import emailjs from "@emailjs/browser"
import { Splide, SplideSlide } from "@splidejs/react-splide"

// Default theme
import "@splidejs/react-splide/css"

// or other themes
import "@splidejs/react-splide/css/skyblue"
import "@splidejs/react-splide/css/sea-green"

// or only core styles
import "@splidejs/react-splide/css/core"

//TO-Do variable to switch answers layout. full width and then 50% 2 a row

const BasicGrid = styled.div`
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
`
const QuestionCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [endQuiz, setEndQuiz] = useState(false)
  const [score, setScore] = useState(0)
  const [avg, setAvg] = useState(0)
  const [showAns, setShowAns] = useState(false)
  const [obj1, setObj1] = useState([])
  const [contactObj, setContactObj] = useState([])
  const [questions, setQuestions] = useState([...randQustions])
  const [questions2, setQuestions2] = useState([...randQustions2])
  const [formAn, setFormAn] = useState({ name: null, email: null })

  //function to calc score and show correct answer
  const handleAnswerClick = (isCorrect, e) => {
    // let obj = [...obj1]
    let obj = {
      id: "",
      question: "",
      answer: ""
    }
    console.log(obj1)
    console.log(questions)
    if (obj1[currentIndex] === undefined) {
      console.log(currentIndex)
      obj["question"] = questions[currentIndex].question
      obj["answer"] = e.target.value
      obj["id"] = currentIndex
      console.log(obj["answer"])

      if (obj.answer === "New Website") {
        setQuestions([...randQustions2])
        console.log(typeof randQustions)
        console.log(randQustions2)
      }
      console.log(questions)
      setObj1((prev) => [...prev, obj])
      nextQuestion(obj.answer)
      return
    } else {
      obj1[currentIndex]["question"] = questions[currentIndex].question
      obj1[currentIndex]["answer"] = e.target.value
      obj1[currentIndex]["id"] = currentIndex
      nextQuestion(obj.answer)
      return
    }

    setShowAns(true)
    // if (isCorrect) {
    //    setScore((prev) => prev + 1);
    // }
    // nextQuestion()
    // setTimeout(() => {
    //    nextQuestion()
    // }, 500)
  }

  //function to go to next question if within bounds of quiz length and hide correct answer
  // if ouside the bounds of the quiz array, the quiz will end
  const nextQuestion = (et) => {
    setShowAns(false)
    if (currentIndex === questions.length - 1) {
      endOfQuiz()
    } else {
      setCurrentIndex((prev) => prev + 1)
    }
    if (et === "New Website") {
      setCurrentIndex((prev) => 0)
    }
  }
  const prevQuestion = () => {
    setShowAns(false)
    if (currentIndex === questions.length - 1) {
      endOfQuiz()
    } else {
      setCurrentIndex((prev) => prev - 1)
    }
  }

  //function to pre-emptively end quiz
  const seeResults = () => {
    setEndQuiz(true)
    setAvg((score / currentIndex) * 100)
  }

  const endOfQuiz = () => {
    setEndQuiz(true)
    setCurrentIndex((prev) => prev + 1)
    setAvg((score / currentIndex) * 100)
  }

  //function to result all variables
  const reset = () => {
    setEndQuiz(false)
    setCurrentIndex(0)
    setScore(0)
    setShowAns(false)
  }
  console.log(obj1)
  function BearCounter() {
    const bears = useAppStore((state) => state.bears)
    return <h1>{bears} around here ...</h1>
  }
  function BearAdd() {
    const addBear = useAppStore((state) => state.increasePopulation)

    return <button onClick={addBear}>one up</button>
  }

  function CurrentState() {
    const nuts = useStore()
    const currentState = useAppStore((state) => state)
    console.log(`Clong= ${JSON.stringify(currentState)}`)
    return <h1>={currentState.bears}</h1>
  }
  const [validated, setValidated] = useState(false)

  const form = useRef()
  const handleSubmit = (event) => {
    obj1.push(formAn)
    console.log(obj1)

    let send = obj1.map((val) => {
      return `${val.question}:\n${val.answer}\n`
    })
    event.preventDefault()
    emailjs
      .send(
        "service_372vrmh",
        "template_s2xdesx",
        { message: send },
        "UU5k2iMgcoj086dA0"
      )
      .then((result) => console.log(result))
      .catch((err) => console.log(err))
    // const form = event.currentTarget
    // if (form.checkValidity() === false) {
    //   event.preventDefault()
    //   event.stopPropagation()
    // }

    setValidated(true)
  }

  // if (endQuiz) {
  //   return (
  //     <>
  //       {endQuiz && <ShowConfetti whenToShow={endQuiz} />}
  //       <Box>
  //         <div className="spacer"></div>
  //         <div className="top" style={{ textAlign: "center" }}>
  //           <ShowMessage avg={avg} />
  //           <p>
  //             you scored <strong>{score}</strong> out of{" "}
  //             <strong>{currentIndex}</strong> ={" "}
  //             <strong>{avg.toFixed(1)}%</strong>
  //           </p>
  //         </div>
  //         <Button onClick={reset}>Start over?</Button>
  //       </Box>
  //     </>
  //   )
  // }
  if (endQuiz) {
    return (
      <Box className="question-box m-full-y">
        <div className="contact">
          <h1>Last Step!</h1>
          <Form
            ref={form}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className="form-floating mb-3" id="Full Name">
              <input
                required
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                onChange={(e) => setFormAn({ ...formAn, name: e.target.value })}
              ></input>
              <label htmlFor="floatingInput">
                <font color="red">Name</font>
              </label>
            </div>
            <div className="form-floating mb-3" id="Email">
              <input
                required
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                onChange={(e) =>
                  setFormAn({ ...formAn, email: e.target.value })
                }
              ></input>
              <label htmlFor="floatingInput">
                <font color="red">Email</font>
              </label>
            </div>
            <div className="form-floating mb-3" id="Phone Number">
              <input
                required
                type="number"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              ></input>
              <label htmlFor="floatingInput">
                <font color="red">Phone Number</font>
              </label>
            </div>
            <div className="form-floating mb-3" id="Call Time">
              <Form.Select aria-label="Default select example">
                <option>Select Time</option>
                <option value="1">Morning (9:00AM-12:00PM)</option>
                <option value="2">Noon (12:30PM-3:30PM)</option>
                <option value="3">evening (4:00PM-6:30PM)</option>
              </Form.Select>
              <label htmlFor="floatingInput">
                <font color="red">Best Time To Call</font>
              </label>
            </div>
            <Button type="submit">Submit form</Button>
          </Form>
        </div>
      </Box>
    )
  }
  return (
    <>
      <Splide
        aria-label="My Favorite Images"
        data-splide='{"type":"loop","perPage":3, "width":"100%", "focus":0}'
        options={{
          rewind: true,
          gap: "1rem"
        }}
      >
        <SplideSlide>
          <img src="./../2.png" alt="Imagde 1" />
        </SplideSlide>
        ssj
        <SplideSlide>
          <img src="./../2.png" alt="Imadge 2" />
        </SplideSlide>
      </Splide>

      <div className="flex flex-col">
        <div className="top mt-3">
          <h6 className="top-text mb-2">
            question {currentIndex + 1} of {questions.length}
          </h6>

          <div className="w-full bg-gray-200 rounded-full h-5 dark:bg-gray-700 mb-4">
            <div
              className="bg-blue-600 h-5 rounded-full"
              style={{ width: currentIndex * (100 / questions.length) + "%" }}
            >
              <span
                className="relative bottom-1.5 m-auto"
                style={{ width: "200px" }}
              ></span>
            </div>
          </div>
          <div className="question">
            <h1 className="question-text mb-4">
              {questions[currentIndex].question}
            </h1>
          </div>
        </div>
        <div className="flex md:flex-col sm:flex-row">
          {questions[currentIndex].answers.map((answer, key) => (
            <Button
              className={`inline-flex mb-8 justify-center items-center
                ${
                  questions[currentIndex].answers.length === key + 1 &&
                  questions[currentIndex].answers.length % 2 !== 0
                    ? "flex-1"
                    : ""
                }
              `}
              onClick={(e) => {
                handleAnswerClick(answer.isCorrect, e)
              }}
              key={key}
              disabled={showAns}
              value={answer.answerText}
            >
              {answer.answerText}
            </Button>
          ))}
        </div>
        <Box className="bottom">
          <Button className="secondary" onClick={prevQuestion}>
            go back
          </Button>
          <div className="ml-[-1rem] mr-[-1rem] bg-slate-400 text-right">
            <h2>View our work</h2> <h2>View our work</h2> <h2>View our work</h2>{" "}
            <h2>View our work</h2> <h2>View our work</h2> <h2>View our work</h2>{" "}
            <h2>View our work</h2> <h2>View our work</h2>
          </div>
        </Box>
      </div>
    </>
  )
}

export default QuestionCard
