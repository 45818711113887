import React from "react"

const Header = ({ avg }) => {
  let message = {
    text: "",
    emoji: ""
  }

  if (avg >= 80) {
    message.text = "Excellent Work!"
    message.emoji = "🎉"
  } else if (avg >= 70) {
    message.text = "Good Work!"
    message.emoji = "🥂"
  } else {
    message.text = "Keep Practicing"
    message.emoji = "💪"
  }

  return (
    <>
      <div className=" bg-black h-20 w-screen ml-[-1rem] pl-[1rem] flex items-center justify-center border-b-4 border-b-orange-400">
        <span className="">Sacredether Creatives</span>
      </div>
      <div class="bg-indigo-300 w-screen ml-[-1rem] ">
        <img className="w-full" src="1.png" />
      </div>
    </>
  )
}

export default Header
