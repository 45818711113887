const questions = [
  {
    question: "Choose a service to begin",
    answers: [
      { answerText: "New Website", media: null },
      { answerText: "Website redeisgn", media: null },
      { answerText: "Mobile App", media: null }
    ]
  },
  {
    question: "Do you have a logo?",
    answers: [
      { answerText: "New Website", media: null },
      { answerText: "Website redeisgn", media: null },
      { answerText: "Mobile App", media: null }
    ]
  },
  {
    question:
      "Do you have Information about your business and/or Images ready that will helps us design your website?",
    answers: [
      { answerText: "I have all my documents and images ready!" },
      { answerText: "I'll need time to gather somethings" },
      { answerText: "I'm not sure and will need help" }
    ]
  },
  {
    question: "How many pages?",
    answers: [
      { answerText: "1-5" },
      { answerText: "5-9" },
      { answerText: "10+" },
      { answerText: "I'm not sure" }
    ]
  },
  {
    question: "How soon are you looking to start?",
    answers: [
      { answerText: "Immediately" },
      { answerText: "Within a few weeks" },
      { answerText: "I'm not sure" }
    ]
  },
  {
    question: "How soon are you looking to start?",
    answers: [
      { answerText: "Immediately" },
      { answerText: "Within a few weeks" },
      { answerText: "I'm not sure" }
    ]
  },
  {
    question: "Size of your business?",
    answers: [
      { answerText: "Small" },
      { answerText: "Medium" },
      { answerText: "Large" }
    ]
  }

  // Do you have a logo?
  // Do you have images or documents to upload?* (How much time do you need to gather images and infromation for your website?)
  // What is your budget range for the project?*()
  // How soon are you looking to start?
  // Upload files now or later depending on the type of movemment done in the initial test before comitting
  // the secound row for questioning for the epic adventure to begin and the old to end as if
  // it wasnt a neccesary evil to be abke to move forward to evolving in the
]

const questionsWeb = [
  {
    question: "What provider are you with?",
    answers: [
      { answerText: "wix", isCorrect: true },
      { answerText: "shop", isCorrect: false }
    ]
  },
  {
    question: "What provider3 are you with?",
    answers: [
      { answerText: "Shopify", isCorrect: false },
      { answerText: "Wix", isCorrect: true },
      { answerText: "Square Space", isCorrect: false },
      { answerText: "Wordpress", isCorrect: false },
      { answerText: "Custom Built", isCorrect: false }
    ]
  }
]

const questionsMobile = [
  {
    question: "What provider are you with?",
    answers: [
      { answerText: "wix", media: null },
      { answerText: "shop", media: null }
    ]
  },
  {
    question: "What provider are you with?",
    answers: [
      { answerText: "Shopify", media: null },
      { answerText: "Wix", media: null },
      { answerText: "Square Space", media: null },
      { answerText: "Wordpress", media: null },
      { answerText: "Custom Built", media: null }
    ]
  }
]

export let mobileQuestions = questionsMobile
export let randQustions = questions.sort()
export let randQustions2 = questionsWeb
