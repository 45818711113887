import $ from "jquery"

// $ is a shorthand for jquery() function
//$(document).ready is when dom is ready -- $(window).load() waits for images

$(document).ready(function () {
  console.log(1)
  //   $.ajax({
  //     url: "https://api.asrtic.edu/api/v1/artworks/129884",
  //     success: function (result) {
  //       alert(result.data.id)
  //     },
  //     error: function (err) {
  //       alert(err)
  //     }
  //   })
})

//
$(window).ready(function () {
  console.log(sessionStorage)
  if (sessionStorage.getItem("dontL8") == null) {
    // $("#yellow-box").delay(3000).fadeOut("slow")
    // sessionStorage.setItem("dontL7", "true")
    $(this).click(function () {
      $("#yellow-box").delay(3000).fadeOut("slow")
    })
  } else {
    $("#yellow-box").hide()
  }
})
