import "./App.css"
import QuestionCard from "./components/QuestionCard"
import Header from "./components/Header"
import { MainApp } from "./components/PageElements/MainApp"
import { theme } from "./data/AppTheme"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import Div100vh from "react-div-100vh"
import { useState } from "react"

const GlobalStyle = createGlobalStyle`
  body{
    background: ${({ theme }) => theme.surface1};
    color: ${({ theme }) => theme.text1};
    font-size: clamp(1rem, 8vw, 1.3rem);
    > * {
      transition: 0.25s ease all;
    }
  }
  h1{
    font-size: clamp(1.2rem, 8vw, 2.8rem);
  }
`
function App() {
  const [spinner, setSpinner] = useState([
    "absolute z-50 w-full h-full flex justify-center items-center bg-black p-20"
  ])
  // setTimeout(() => {
  //   setSpinner(
  //     "animate-fade absolute z-50 w-full h-full overflow-hidden flex justify-center items-center bg-black display-none"
  //   )
  // }, 3000)
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div id="yellow-box" className={spinner}>
        <img src="./logo.png" alt="these" id="image1" />
      </div>
      <MainApp className="app">
        <Header />
        <QuestionCard />
        <p style={{ fontSize: ".7rem", textAlign: "center" }}>
          Visit Our Main Website SacredEther.com © {new Date().getFullYear()}
        </p>
      </MainApp>
    </ThemeProvider>
  )
}

export default App
